import React, { Component } from "react";
import Footer from "./Footer.js";
class Contact extends Component {
  render() {
    return (
      <div>
        <div className="hero-image-contact">
        </div>
        <div className="container-fluid">
          <br />
          <br />
          <h1 className="text-center heading">
            <strong>Request A Muchow Concrete Construction Bid</strong>
          </h1>
          <br />
          <br />
          <p className="text-center lead">
            Whether it's a concrete patio to show off to your friends, or a 500
            foot long driveway, Duane Muchow Concrete will get the job done
            safely and with world-class quality from set up until finish.
          </p>
          <br />
          <br />
          <br />
          <hr className="my-1" />
          <br />
          <br />
          <br />
          <h2 className="text-center number-email">
            Cell:
            <a href="tel:+1-217-502-0285" className="number-link">
              <span className="number-text"> 217-502-0285</span>
            </a>
            <br />
            Email:
            <a href="mailto:info@muchowconcrete.com" className="email-link">
              <span className="number-text"> duane@muchowconcrete.com</span>
            </a>
          </h2>
          <br />
          <br />
          <br />
          <br />
          <hr />
        </div>
        <Footer />
      </div>
    );
  }
}

export default Contact;
