import React, {Component} from "react";

class Intro extends Component {
render() {
    return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col">
            <h3 className="text-center mt-5 heading-main"><strong>Springfield, Illinois Residential and Semi-Commercial Concrete Construction</strong></h3>
            <p className="text-center mt-3 mb-5 intro">Duane Muchow Concrete is a residential and semi-commercial concrete construction company serving homeowners and small businesses throughout the 
              central Illinois communities of Springfield, 
              Chatham, Rochester, Williamsville, Jacksonville, Auburn, Pleasant Plains and New Berlin.  Whether you'd like a new driveway, stamp-concrete patio, or 
               retaining wall, Duane Muchow Concrete will go above the standard to build a concrete product that lasts through tough mid west season changes and looks great on your business or property.       </p>
               <br />
          </div>
        </div>
      </div>
    </div>
    )
}
}

export default Intro;