import React, { Component } from "react";
import Footer from "./Footer.js";
import { Carousel } from "react-bootstrap";
import PatioThree from "./images/duane_muchow_patio_5.jpeg";
import PatioFour from "./images/duane_muchow_concrete_pavers.jpeg";
import PatioFive from "./images/duane_muchow_concrete_patio_4_carousel.jpg";
import PatioSix from "./images/duane_muchow_concrete_patio_6.jpeg";
import PatioSeven from "./images/duane_muchow_concrete_patio_7.jpeg";

class Patios extends Component {

componentDidMount() {
  window.scrollTo(0,0);
}

  render() {
    return (
      <div>
        <div class="hero-image-patios"></div>
        <div class="container-fluid">
          <br />
          <br />
          <br />
          <br />
          <h1 class="text-center heading">
            <strong>Duane Muchow Concrete Patios</strong>
          </h1>
          <br />
          <p class="text-center lead">
            Custom designed patios using your choice of pavers or stamp
            concrete. We also can design landscaping paths and walkways to your
            specs. Just use your imagination! All of our concrete products
            including patios are re-bar reinforced, graded with the accuracy of
            laser guided technology and each job has concrete carefully mixed
            and poured according to the individual demands of each job.
          </p>
          <br />
          <br />
          <br />
          <br />
          <h3 className="text-center carousel-heading">
            Please have a look at some of our finished patio projects
          </h3>
          <br />
        </div>
        <Carousel>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={PatioThree}
              alt="Second slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={PatioFour} alt="Third slide" />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={PatioFive} alt="Fourth slide" />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={PatioSix} alt="Fifth slide" />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={PatioSeven} alt="Sixth slide" />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
        </Carousel>

        <Footer />
      </div>
    );
  }
}

export default Patios;
