import React, { Component } from "react";
import BigLogo from "./images/duane_muchow_concrete_large_nav_logo.png";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap";
import About from "./About";
import Home from "./Home";
import Contact from "./Contact";
import Patios from "./Patios";
import Flatwork from "./Flatwork";
import Steps from "./Steps";
import Walls from "./Walls"

class NavBar extends Component {
  toggleNav = () => {
    var x = document.getElementById("myTopnav");
    if (x.className === "topnav") {
      x.className += " responsive";
    } else {
      x.className = "topnav";
    }
  };
  render() {
    return (
      <Router>
      <Container fluid>
        <Navbar collapseOnSelect expand="lg">
          <Navbar.Brand>
            <Link to="/">
            <img
              alt=""
              src={BigLogo}
              className="d-inline-block align-top"
            />{" "}
            </Link>
          </Navbar.Brand>
          <button type="button" className="d-block btn btn-outline-dark btn-lg mx-auto request-bid"><Link to="/contact" className="request-link"><span className="service-button-text"><strong>REQUEST BID</strong></span></Link></button>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              <Link to="/home" className="nav-links-home">Home</Link>
              <NavDropdown title="Services" id="collasible-nav-dropdown">
                <NavDropdown.Item><Link to="/patios" className="nav-links">Patios</Link></NavDropdown.Item>
                <NavDropdown.Item><Link to="/flatwork" className="nav-links">Flatwork</Link></NavDropdown.Item>
                <NavDropdown.Item><Link to="/steps" className="nav-links">Steps</Link></NavDropdown.Item>
                <NavDropdown.Item><Link to="/walls" className="nav-links">Walls</Link></NavDropdown.Item>
              </NavDropdown>
              <Link to="/about" className="nav-links">About</Link>
              <Link to="/contact" className="nav-links">Contact</Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Switch>
        <Route path="/walls">
              <Walls />
            </Route>
        <Route path="/steps">
              <Steps />
            </Route>
        <Route path="/flatwork">
              <Flatwork />
            </Route>
        <Route path="/patios">
              <Patios />
            </Route>
        <Route path="/contact">
              <Contact />
            </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
      </Container>
      </Router>
    );
  }
}

export default NavBar;
