import React, { Component } from "react";
import Footer from "./Footer.js";
import { Carousel } from "react-bootstrap";
import FlatworkOne from "./images/duane_muchow_concrete_flatwork_one_carousel.jpg";
import FlatworkTwo from "./images/duane_muchow_concrete_flatwork_two_carousel.jpg";
import FlatworkThree from "./images/duane_muchow_flatwork_3_carousel.jpg";

class Flatwork extends Component {

  componentDidMount() {
    window.scrollTo(0,0);
  }
  
  render() {
    return (
      <div>
        <div class="hero-image-flatwork"></div>
        <div class="container-fluid">
          <br />
          <br />
          <br />
          <br />
          <h1 class="text-center heading">
            <strong>Duane Muchow Concrete Flatwork</strong>
          </h1>
          <br />
          <p class="text-center lead">
            At Duane Muchow Concrete, we have all of your semi-commercial and
            residential flatwork concrete needs and decades of experience to
            show for it. All of our concrete products including flatwork are
            re-bar reinforced, graded with the accuracy of laser guided
            technology and each job has concrete carefully mixed and poured
            according to the individual demands of each job.
          </p>
          <br />
          <br />
          <br />
          <h2 className="text-center carousel-heading">
            Please have a look at some of our finshed flatwork projects
          </h2>
          <br />
        </div>
        <Carousel>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={FlatworkOne}
              alt="First slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={FlatworkTwo}
              alt="Second slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={FlatworkThree}
              alt="Third slide"
            />
          </Carousel.Item>
        </Carousel>

        <Footer />
      </div>
    );
  }
}

export default Flatwork;
