import React, { Component } from "react";
import StampPatio from "./images/duane_muchow_stamp_concrete_2.jpeg";
import Footer from './Footer.js';
import { Container, Button } from 'react-bootstrap';

class About extends Component {
  render() {
    return (
      <Container fluid>
        <div className="container-fluid">
          <h1 className="text-center heading">
            <br />
            <strong>About Duane Muchow Concrete</strong>
          </h1>
          <br />
          <p className="text-center lead">
            The Muchow name has been synonymous with concrete in central
            Illinois for several generations. With a background of 45 years in
            concrete construction, Duane Muchow has a reputation for spectacular
            results, plus warm and friendly customer service. Duane's past
            experience with residential and commercial construction has helped
            him understand proper reinforcement and quality practices. Duane
            Muchow Concrete's finished product will stand the test of time and
            withstand harsh environmental conditions.
          </p>
          <br />
          <br />
          <br />
        </div>
        <div className="container-fluid">
          <img
            className="img-fluid"
            alt="Duane Muchow stamping concrete patio"
            src={StampPatio}
          />
        </div>
        <div className="container-fluid">
          <br />
          <br />
          <br />
          <br />
          <br />
          <h5 className="text-center request-text">
            <strong>Request A Bid</strong>
          </h5>
          <p className="text-center lead">
            Have a question? Want an estimate?
            <br />
            Call us at 217-502-0258 to discuss
            <br />
            your next concrete construction project.
          </p>
          <Button
            className="d-block btn btn-warning btn-lg mb-3 mx-auto contact-bid"
          >
            <a className="contact-button-text" href="tel:+2175020285"><span>Contact Us</span></a>
          </Button>
          <br />
          <br />
          <br />
          <br />
          <hr />
        </div>
        <Footer />
      </Container>
    );
  }
}

export default About;