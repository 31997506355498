import React, { Component } from "react";
import Patio from "./images/duane_muchow_concrete_patio.jpeg";
import Flatwork from "./images/duane_muchow_concrete_sidewalk.jpeg";
import Steps from "./images/duane_muchow_concrete_steps.jpeg";
import Wall from "./images/duane_muchow_concrete_block_wall.jpeg";
import AOS from "aos";
import { BrowserRouter as Router, Link, Redirect } from "react-router-dom";
import "aos/dist/aos.css";

AOS.init();

class Services extends Component {
  handlePatiosClick = () => {
    return(
    <Router>
    <Redirect to={{
      pathname: "/patios"
    }} />  
    </Router>
    )
  };

  handleFlatworkClick = () => {
    return(
      <Router>
    <Redirect to={{
      pathname: "/flatwork"
    }} />  
    </Router>
    )
  };

  handleStepsClick = () => {
    return(
      <Router>
    <Redirect to={{
      pathname: "/steps"
    }} />  
    </Router>
    )
  };

  handleWallsClick = () => {
    return(
      <Router>
    <Redirect to={{
      pathname: "/walls"
    }} />  
    </Router>
    )
  };
  render() {
    return (
      <div className="container-fluid">
        <div className="container-fluid mt-5 services">
          <hr />
          <br />
          <div className="row-services-services">
            <div className="col text-center services-column">
              <h3 className="d-block text-center heading-2">
                <strong>Our Services</strong>
              </h3>
              <br />
              <br />
            </div>
          </div>
          <div className="row-services">
            <div className="col ml-4 services-column" data-aos="zoom-in">
              <br />
              <img
                src={Patio}
                alt="concrete patio"
                className="rounded mx-auto d-block border border-dark mb-2"
              />
              <p className="text-center patios-header">
                <strong>Patios</strong>
              </p>
              <p className="text-center patios-header-description">
                At Muchow Concrete we specialize in building custom concrete
                patios made with pavers, stamp and additional stonework.
              </p>
              <Link className="service-links" to="/patios" onClick={this.handlePatiosClick}>
              <button
                type="button"
                className="d-block btn btn-outline-dark btn-lg mx-auto service-button"
              >
                <span className="service-button-text">
                  <strong>Learn More</strong>
                </span>
              </button>
              </Link>
              <br />
            </div>
            <div className="col ml-4 services-column" data-aos="zoom-in">
              <br />
              <img
                src={Flatwork}
                alt="concrete sidewalk"
                className="rounded mx-auto d-block border border-dark mb-2"
              />
              <p className="text-center patios-header">
                <strong>Flatwork</strong>
              </p>
              <p className="text-center patios-header-description">
                From set-up, to pour, to finish, we pour driveways, basements,
                garage floors, sidewalks and pathways. Click below to learn
                more.
              </p>
              <Link className="service-links" to="/flatwork" onClick={this.handleFlatworkClick}>
              <button
                type="button"
                className="d-block btn btn-outline-dark btn-lg mx-auto service-button"
              >
                <span className="service-button-text">
                  <strong>Learn More</strong>
                </span>
              </button>
              </Link>
              <br />
            </div>
            <div className="col ml-4 services-column" data-aos="zoom-in">
              <img
                src={Steps}
                alt="concrete stairs"
                className="rounded mx-auto d-block border border-dark mb-2"
              />
              <p className="text-center patios-header">
                <strong>Steps</strong>
              </p>
              <p className="text-center patios-header-description">
                Create a new staircase for your home or buisiness entrance/exit.
                Choose a custom design and multiple types of finishes.
              </p>
              <Link className="service-links" to="/steps" onClick={this.handleStepsClick}>
              <button
                type="button"
                className="d-block btn btn-outline-dark btn-lg mx-auto service-button"                
              >
                <span className="service-button-text">
                  <strong>Learn More</strong>
                </span>
              </button>
              </Link>
              <br />
            </div>
            <div className="col ml-4 services-column" data-aos="zoom-in">
              <img
                src={Wall}
                alt="concrete block landscaping wall"
                className="rounded mx-auto d-block border border-dark mb-2"
              />
              <p className="text-center patios-header">
                <strong>Walls</strong>
              </p>
              <p className="text-center patios-header-description-walls">
                We design retaining walls to protect your property from water
                damage and erosion and decorative walls for your landscaping or
                backyard patios.
              </p>
              <Link className="service-links" to="/walls" onClick={this.handleWallsClick}>
              <button
                type="button"
                className="d-block btn btn-outline-dark btn-lg mx-auto service-button"                
              >
                <span className="service-button-text">
                  <strong>Learn More</strong>
                </span>
              </button>
              </Link>
              <br />
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <hr />
      </div>
    );
  }
}

export default Services;
