import React, { Component } from "react";
import { Container, Row, Col,} from "react-bootstrap";
import FooterLogo from "./images/duane_muchow_concrete_footer_logo.png";
import FacebookLogo from "./images/FACEBOOK-512 (1).png";
import MailchimpForm from "./MailchimpForm";


class Footer extends Component {

 onSubmit = token => {
    document.getElementById("demo-form").submit();
  }
  render() {
    return (
      <Container className="footer" fluid="md">
        <br />
        <br />
        <br />
        <h5 className="text-center footer-call">
              <strong>
                LOOKING TO START A DUANE MUCHOW CONCRETE PROJECT? CONTACT US
                TODAY TO SCHEDULE A BID!
              </strong>
            </h5>
        <Row id="contact-section" className="form">
          <Col xs={12} md={6} className="form-col">
            <br />
            <br />
             <MailchimpForm />  
            <br />
            <br />
          </Col>
          <Col xs={12} md={6}>
            <div className="col ml-4 contact-col">
              <br />
              <img
                className="mx-auto d-block"
                alt="Duane Muchow Concrete logo"
                id="footer-logo-id"
                src={FooterLogo}
              ></img>
              <p className="text-center patios-header">
                Cell:
                <a className="number" href="tel:+2175020285">
                  <span className="footer-number-span">217-502-0285</span>
                </a>
              </p>
              <p className="text-center patios-header">
                Email:
                <a className="email" href="mailto:duane@muchowconcrete.com">
                  <span>duane@muchowconcrete.com</span>
                </a>
              </p>
              <a href="https://www.facebook.com/DuanesConcreteConstruction/">
                <img
                alt="Duane Muchow Concrete facebook account"
                  className="mx-auto mt-2 d-block facebook-logo"
                  src={FacebookLogo}
                ></img>
              </a>
              <hr />
              <h6 className="mb-5 text-center copy">
                &copy;2020 Website by{" "}
                <a className="solquinox" href="https://www.reidmuchow.com/">
                  <span>Solquinox Productions</span>
                </a>
              </h6>
            </div>
          </Col>
        </Row>
        <br />
        <br />
        <br />
      </Container>
    );
  }
}

export default Footer;
