import React, { Component } from "react";
import Mailchimp from "react-mailchimp-form";

class MailchimpForm extends Component {
  render() {
    return (
      <Mailchimp
        action="https://muchowconcrete.us18.list-manage.com/subscribe/post?u=9951941ef4748619cf894c531&amp;id=29aeb8f924"
        fields={[
          {
            name: "FNAME",
            placeholder: "Full Name",
            type: "name",
            required: true
          },
          {
            name: 'EMAIL',
            placeholder: 'Email',
            type: 'text',
            required: true
          },
          {
            name: 'PHONE',
            placeholder: 'Phone',
            type: 'text',
            required: true
          },
          {
            name: 'MMERGE2',
            placeholder: 'Comments',
            type: 'text',
            required: true
          }
        ]}
        messages = {
          {
            sending: "Sending...",
            success: "Thank you for reaching out!  We'll be in contact with you soon.",
            error: "An unexpected internal error has occurred.",
            empty: "You must fill in the form.",
            duplicate: "Too many submit attempts for this email address",
            button: "SUBMIT"
          }
        }
      />
    );
  }
}

export default MailchimpForm;
