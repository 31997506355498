import React, { Component } from "react";
import BannerLogo from "./images/duane_muchow_concrete_large_banner.png";
import { BrowserRouter as Router, Link, Redirect } from "react-router-dom";
class HeroContainer extends Component {

  handleClick = () => {
    return (
    <Router>
    <Redirect to={{
      pathname: "/about"
    }} />      
  </Router>
    )}
  render() {
    return (
      
        <div>
          <div className="container-fluid hero-image">
            <div className="hero-text">
              <br />
              <img
                className="d-block mx-auto logo-banner"
                alt="Duane Muchow Concrete Logo"
                src={BannerLogo}
              />
              <h3 className="slogan-text">
                40 YEARS PLUS OF EXCEPTIONAL WORK.
              </h3>
              <h6 className="tag-line">
                Knowledgable and Customer Focused Concrete Services.{" "}
              </h6>
              <br />
              <button
                type="button"
                className="d-block btn btn-outline-dark btn-lg mx-auto learn-button"
              >
                <Link to="/about" onClick={this.handleClick} className="nav-links">
                  <span className="service-button-text">
                    <strong>LEARN MORE</strong>
                  </span>
                </Link>
              </button>
              <br />
              <br />
            </div>
          </div>
        </div>
    );
  }
}

export default HeroContainer;
