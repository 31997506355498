import React, { Component } from "react";
import Footer from "./Footer.js";
import { Carousel } from "react-bootstrap";
import StepsOne from "./images/duane_muchow_concrete_steps_1.jpeg";
import StepsTwo from "./images/duane_muchow_concrete_steps_3.jpeg";
import StepsThree from "./images/duane_muchow_concrete_steps_2.jpeg";

class About extends Component {
  
  componentDidMount() {
    window.scrollTo(0,0);
  }
  render() {
    return (
      <div>
        <div class="hero-image-steps"></div>
        <div class="container-fluid">
          <h1 class="text-center heading">
            <br />
            <strong>Duane Muchow Concrete Steps</strong>
          </h1>
          <br />
          <br />
          <p class="text-center lead">
            Whether you’d like a new concrete stair set for your front porch or
            business, or custom designed stairs to match a newly renovated
            addition to your home, Duane Muchow can build concrete stairs for
            your needs. We also specialize in porch caps for front and back
            porches. All of our concrete products including steps are re-bar
            reinforced, graded with the accuracy of laser guided technology and
            each job has concrete carefully mixed and poured according to the
            individual demands of each job.
          </p>
          <br />
          <br />
          <br />
          <h2 className="text-center carousel-heading">
            Please have a look at some of our finshed step projects
          </h2>
          <br />
        </div>
        <Carousel>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={StepsOne}
              alt="First slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={StepsTwo}
              alt="Second slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={StepsThree}
              alt="Third slide"
            />
          </Carousel.Item>
        </Carousel>

        <Footer />
      </div>
    );
  }
}

export default About;
