import React, { Component } from "react";
import Footer from "./Footer.js";
import { Carousel } from "react-bootstrap";
import WallOne from "./images/duane_muchow_patio_wall_1.jpeg";

class About extends Component {

  componentDidMount() {
    window.scrollTo(0,0);
  }
  
  render() {
    return (
      <div>
        <div class="hero-image-walls"></div>
        <div class="container-fluid">
          <h1 class="text-center heading">
            <br />
            <strong>Duane Muchow Concrete Walls</strong>
          </h1>
          <br />
          <br />
          <p class="text-center lead">
            We are at the mercy of the elements on a daily basis and Duane
            Muchow Concrete can build you a retaining wall that can help protect
            your property from soil erosion and ground settlement. In his years
            working in commercial concrete, Duane Muchow helped pour many of the
            basement foundations Springfield, IL residents now call home. Coming
            from this background, Duane Muchow concrete can design any concrete
            wall you need for your home or business. In addition to retaining
            walls, Duane Muchow Concrete also designs landscaping block walls
            for your front or back yard patios. All of our concrete products
            including walls are re-bar reinforced, graded with the accuracy of
            laser guided technology and each job has concrete carefully mixed
            and poured according to the individual demands of each job.
          </p>
          <br />
          <br />
          <br />
          <h2 className="text-center carousel-heading">
            Please have a look at some of our finshed wall projects
          </h2>
          <br />
        </div>
        <Carousel>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={WallOne}
              alt="First slide"
            />
          </Carousel.Item>
        </Carousel>

        <Footer />
      </div>
    );
  }
}

export default About;
