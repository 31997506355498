import React from 'react';
import NavBar from './NavBar.js';

function App() {
  return (
<div className="main-container">
  <NavBar />
</div>
  );
}
export default App;
  