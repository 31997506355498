import React, { Component } from "react";
import { Container } from 'react-bootstrap';
import HeroContainer from "./HeroContainer.js";
import Services from "./Services.js";
import Intro from "./Intro.js";
import Footer from "./Footer.js";

class Home extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      phone: "",
      comments: ""
    };
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = event => {};

  render() {
    return (
<Container fluid>
    <HeroContainer />
    <Intro />
    <Services />
    <Footer name={this.state.name}
     email={this.state.email}
     phone={this.state.phone}
     comments={this.state.comments}
     handleChange={this.handleChange}/>
</Container>
    );
  }
}

export default Home;
